import basicBoxImage from "../assets/images/basic-box-image.png";
import completeBoxImage from "../assets/images/complete-box-image.png";
import { SubscriptionTypes } from "./subscription-types";

interface PlanValue {
  subscription: number;
}

interface PlanUrl {
  subscription: string;
}
export interface PlanItemMonth {
  title?: string;
  items: string[];
  value?: PlanValue;
  url?: PlanUrl;
  paymentUrl: string;
  month: number;
}

export interface PlanItem {
  name: string;
  type: string;
  boxName: string;
  initialValue: number;
  highlighted?: boolean;
  boxImage: string;
  months: PlanItemMonth[];
}

const plans = {
  BASIC: {
    name: "Assinatura básica",
    boxName: "básica",
    type: SubscriptionTypes.BASIC,
    initialValue: 178.4,
    boxImage: basicBoxImage,
    months: [
      {
        items: ["Cavalete", "Móbile Munari"],
        value: {
          subscription: 178.4,
        },
        url: {
          subscription: "https://forms.gle/YTWryio7SrpTDoy78",
        },
        paymentUrl: "http://pag.ae/7XzhNBh7H",
        month: 1,
      },
      {
        items: ["Móbile Octaedro", "Móbile Gobbi", "Móbile Dançarinos"],
        value: {
          subscription: 178.4,
        },
        url: {
          subscription: "https://forms.gle/6oWKmF8qYvxB4gei7",
        },
        paymentUrl: "http://pag.ae/7XzhPYLnK",
        month: 2,
      },
      {
        items: ["Móbile Tátil", "Móbile Sonoro", "Pipa de Mão"],
        value: {
          subscription: 178.4,
        },
        url: {
          subscription: "https://forms.gle/MKGobKG8dx1rj1Mn9",
        },
        paymentUrl: "http://pag.ae/7XzhU75G5",
        month: 3,
      },
      {
        items: [
          "Chocalho com Bolas",
          "Chocalho de Argolas",
          "Discos Interconectados",
          "Mordedor",
        ],
        value: {
          subscription: 178.4,
        },
        paymentUrl: "http://pag.ae/7XzhVghS9",
        month: 4,
      },
      {
        items: ["Mapa Sensorial "],
        value: {
          subscription: 178.4,
        },
        paymentUrl: "http://pag.ae/7XzhWc42K",
        month: 5,
      },
      {
        items: ["Bola montessori", "Cesto de Descobertas "],
        value: {
          subscription: 178.4,
        },
        paymentUrl: "http://pag.ae/7XC_pMmG2",
        month: 6,
      },
      {
        items: ["Caixa de Permanência"],
        value: {
          subscription: 184.7,
        },
        paymentUrl: "http://pag.ae/7Xzicmpw7",
        month: 7,
      },
      {
        items: ["Barra de Apoio", "Maraca Indígena "],
        value: {
          subscription: 184.7,
        },
        paymentUrl: "http://pag.ae/7XzieUANK",
        month: 8,
      },
      {
        items: ["Cubos de Madeira"],
        value: {
          subscription: 184.7,
        },
        paymentUrl: "http://pag.ae/7XzihV2-q",
        month: 9,
      },
      {
        items: ["Torre de Encaixe"],
        value: {
          subscription: 184.7,
        },
        paymentUrl: "http://pag.ae/7Xzij1v9n",
        month: 10,
      },
      {
        items: ["Livro Sensorial "],
        value: {
          subscription: 184.7,
        },
        paymentUrl: "http://pag.ae/7Xzikefpq",
        month: 11,
      },
      {
        items: ["Chocalho Andador"],
        value: {
          subscription: 184.7,
        },
        month: 12,
      },
    ],
  },
  COMPLETE: {
    name: "Assinatura completa",
    boxName: "completa",
    type: SubscriptionTypes.COMPLETE,
    initialValue: 238.4,
    boxImage: completeBoxImage,
    months: [
      {
        items: ["Cavalete", "Tapete", "Móbile Munari"],
        value: {
          subscription: 238.4,
        },
        paymentUrl: "http://pag.ae/7XykQ3dpo",
        month: 1,
      },
      {
        items: ["Móbile Octaedro", "Móbile Gobbi", "Móbile Dançarinos"],
        value: {
          subscription: 238.4,
        },
        paymentUrl: "http://pag.ae/7XzcxBajs",
        month: 2,
      },
      {
        items: [
          "Espelho Montessori",
          "Móbile Tátil",
          "Móbile Sonoro",
          "Pipa de Mão",
        ],
        value: {
          subscription: 238.4,
        },
        paymentUrl: "http://pag.ae/7Xzcy5Km5",
        month: 3,
      },
      {
        items: [
          "Chocalho com Bolas",
          "Chocalho de Argolas",
          "Discos Interconectados",
          "Mordedor",
        ],
        value: {
          subscription: 238.4,
        },
        paymentUrl: "http://pag.ae/7XzcyqZg4",
        month: 4,
      },
      {
        items: ["Mapa Sensorial "],
        value: {
          subscription: 238.4,
        },
        paymentUrl: "http://pag.ae/7XzcyVsr5",
        month: 5,
      },
      {
        items: ["Bola montessori", "Cesto de Descobertas "],
        value: {
          subscription: 238.4,
        },
        paymentUrl: "http://pag.ae/7XzcAAow2",
        month: 6,
      },
      {
        items: ["Caixa de Permanência"],
        value: {
          subscription: 259.1,
        },
        paymentUrl: "http://pag.ae/7XzcBxhsN",
        month: 7,
      },
      {
        items: ["Barra de Apoio", "Maraca Indígena "],
        value: {
          subscription: 275.3,
        },
        paymentUrl: "http://pag.ae/7XzcFV1hL",
        month: 8,
      },
      {
        items: ["Cubos de Madeira", "Mini Cilindros de Madeira"],
        value: {
          subscription: 305.9,
        },
        paymentUrl: "http://pag.ae/7XzcGfgYH",
        month: 9,
      },
      {
        items: ["Torre de Encaixe"],
        value: {
          subscription: 318.5,
        },
        paymentUrl: "http://pag.ae/7XzcGFmpq",
        month: 10,
      },
      {
        items: ["Painel Sensorial "],
        value: {
          subscription: 404.0,
        },
        paymentUrl: "http://pag.ae/7XzcH3GYN",
        month: 11,
      },
      {
        items: ["Mesa Sensorial"],
        value: {
          subscription: 404.0,
        },
        month: 12,
      },
    ],
  },
};

export default plans;
